
<!--后台主题框架-->
<template>
  <el-container style="min-height: 100vh">
    <!--侧边栏-->
    <Aside  />
    <el-container>
      <el-header>
        <!--将user信息传递给head组件-->
        <Header :user="user"/>
      </el-header>
      <el-main>
        <!--      内容
      refreshuUser方法用来判断用户信息是否发生变化
      -->
        <div style="width: 100%;">
          <router-view style="flex: 1;" @refreshUser="getUser" />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";
import {request} from "axios";

export default {
  name: "Layout",
  components:{
    Header,
    Aside
  },
  data(){
    return{
      user:{}
    }
  },
  created() {
    this.getUser()
  },
  methods:{
    getUser(){
      this.user = JSON.parse(localStorage.getItem("user"))
    }
  }
}
</script>

<style scoped>

</style>