<template>
  <div id="nav">
    <router-view /><!--根节点访问-->
  </div>
</template>



<script>
export default {
  name:"App",

}
</script>
