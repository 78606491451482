import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../layout/Layout.vue'

const routes = [
    {
    path: '/login',
    name: 'Login',
    component: ()=>import("@/views/Login")
  },{
    path: '/404',
    name: 'error',
    component: ()=>import("@/views/404")
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect:'/index',
    children:[{
      path: 'index',
      name: 'Index',
      component: ()=>import("@/views/Index")
    },{
      path: 'personInfo',
      name: 'PersonInfo',
      component: ()=>import("@/views/PersonInfo")
    },{
      path: 'peopleManage',
      name: 'peopleManage',
      component: ()=>import("@/views/PeopleManage")
    },{
      path: 'shouManage',
      name: 'shouManage',
      component: ()=>import("@/views/ShouManage")
    },{
      path: 'userManage',
      name: 'userManage',
      component: ()=>import("@/views/UserManage")
    },{
      path: 'goodsMange',
      name: 'goodsMange',
      component: ()=>import("@/views/GoodsMange")
    },{
      path: 'goodsRefuse',
      name: 'goodsRefuse',
      component: ()=>import("@/views/GoodsRefuse")
    },{
      path: 'goodsTransIng',
      name: 'goodsTransIng',
      component: ()=>import("@/views/GoodsTransIng")
    },{
      path: 'beginPlace',
      name: 'beginPlace',
      component: ()=>import("@/views/ListBeginPlace.vue")
    },{
      path: 'overPlace',
      name: 'overPlace',
      component: ()=>import("@/views/ListOverPlace.vue")
    },{
      path: 'thingType',
      name: 'thingType',
      component: ()=>import("@/views/ListThingType.vue")
    }
    ]
  },
]

const router = createRouter({
  mode:'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//设置路由守卫
router.beforeEach((to,from,next) => {


  //未找到路由的情况
  if (!to.matched.length){
    const storeMenus = localStorage.getItem("user")
    if (!storeMenus){
      //未登录时，跳向登陆界面
      next('/login')
    }else {
      next('/404')
    }
  }

  next()

})

export default router
