<template>
  <div style="height: 50px;line-height: 50px;border-bottom: 1px solid #ccc; display: flex">
    <div style="flex: 1;">
      <el-breadcrumb style="display: inline-block; margin-left: 10px">
        <el-breadcrumb-item :to="'/'" style="font-size: 15px;"><el-icon><Sunny /></el-icon>华沸生态环保</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="width: 100px;margin-top: 18px">
      <el-dropdown style="width: 150px; cursor: pointer; text-align: right">
        <div style="display: inline-block">
          <img :src="user.avatarUrl" alt=""
               style="width: 40px; height:40px;border-radius: 50%;margin-top: -10px; position: relative; right: 90px">
          <span class="el-dropdown-link" style="margin-top: -25px">
          {{user.name}}
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        </div>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="$router.push('/personInfo')">个人信息</el-dropdown-item>
              <el-dropdown-item @click="exitSystem">退出系统</el-dropdown-item>
            </el-dropdown-menu>
          </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import { ArrowDown } from '@element-plus/icons-vue'

  export default {
    components:{
      ArrowDown
    },
    name: "Header",
    data(){
      return{
        user:{}
      }
    },
    created() {
      let studentStr = localStorage.getItem("user") || "{}"
      this.user = JSON.parse(studentStr)
    },
    methods:{
      exitSystem(){
        localStorage.removeItem('user')//退出系统之后清除用户信息
        this.$message({
          type: "success",
          message: "退出系统"
        })
        this.$router.push('/login')
      }
    },
  };

</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>