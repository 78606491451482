<template>
  <div>
    <el-menu
        :default-active="activeIndex"
        router
        class="el-menu-vertical-demo"
        active-text-color="#ffd04b"
        background-color="#545c64"
        text-color="#fff"
        style="width: 200px; min-height: calc(100vh - 50px);"
    >
      <el-menu-item index="index"><el-icon><HomeFilled /></el-icon>首页</el-menu-item>
      <el-sub-menu index="peopleManage">
        <template #title><el-icon><Bicycle /></el-icon>货车司机</template>
        <el-menu-item index="peopleManage"><el-icon><Bicycle /></el-icon>司机管理</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="shouManage">
        <template #title><el-icon><User /></el-icon>收货人员</template>
        <el-menu-item index="shouManage"><el-icon><User /></el-icon>人员管理</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="userManage">
        <template #title><el-icon><UserFilled /></el-icon>管理员</template>
        <el-menu-item index="userManage"><el-icon><UserFilled /></el-icon>审核管理</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="goodsMange">
        <template #title><el-icon><Memo /></el-icon>货物记录</template>
        <el-menu-item index="/goodsTransIng"><el-icon><Place /></el-icon>运输中</el-menu-item>
        <el-menu-item index="/goodsRefuse"><el-icon><CircleCloseFilled /></el-icon>已拒收</el-menu-item>
        <el-menu-item index="/goodsMange"><el-icon><SuccessFilled /></el-icon>已收货</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="place">
        <template #title><el-icon><More /></el-icon>列表管理</template>
        <el-menu-item index="/beginPlace"><el-icon><Promotion /></el-icon>出发地点</el-menu-item>
        <el-menu-item index="/overPlace"><el-icon><Van /></el-icon>目的地点</el-menu-item>
        <el-menu-item index="/thingType"><el-icon><Van /></el-icon>货物类型</el-menu-item>
      </el-sub-menu>
    </el-menu>
    <div style="text-align: center;background-color: #545c64;color: white;height: 50px">
    </div>
  </div>
</template>

<script >
  import {
    Document,
    Location,
    Setting,
  } from '@element-plus/icons-vue'
  export default {
    components:{
      Document,
      Location,
      Setting,
    },
    data(){
      return{
        activeIndex: '1',
      }
    },
    name: "Aside",
    props:{
      menus:Array
    }

  }
</script>


<style>

</style>